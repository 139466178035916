import React from "react"
import { graphql, Link } from "gatsby"
import {
  Section,
  BreadCrumb,
  Background,
  SectionPageTitle,
  MarkdownContent,
  Container,
} from "../components/SectionTags"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/Header"
import Bg from "../components/Image/IndustryHeroBg"
import EmergencyEstimateCTA from "../components/CallToAction/EmergencyEstimateCTA"
import ContentRightForm from "../components/ContentRightForm"
import BreakpointUp from "../components/Media/BreakpointUp"
import styled from "styled-components"

const MainPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
  justify-content: space-between;
`
const LeftPanel = styled.div`
  position: relative;
  width: 100%;
  padding: 0 20px;
  ${BreakpointUp.lg`	
		flex: 0 0 58.3333%;
		max-width: 58.3333%;
	`}
`
const RightPanel = styled.div`
  position: relative;
  width: 100%;
  padding: 0 20px;
  ${BreakpointUp.lg`	
		flex: 0 0 41.6667%;
		max-width: 41.6667%;
		margin-top:60px;
	`}
  ${BreakpointUp.xl`
		margin-top:150px;
	`}
`

class ContentTemplate extends React.Component {
  render() {
    const pageData = this.props.data.contentfulContentPage
    return (
      <Layout>
        <Seo title={pageData.metaTitle}
          description={pageData.metaDescription.metaDescription}
        />
        <div className="sticky-wrapper sticky">
          <Header />
        </div>
        <Section xpt="120px" xpb="80px" mpt="120px" mpb="90px" pt="90px" pb="60px">
          <Background right="0" height="inherit" ts="rotateY(180deg)"><Bg /></Background>
          <Container maxWidth="1120px">
            <MainPanel>
              <LeftPanel>
                <BreadCrumb>
                  <Link to="/">
                    <span>Home</span>
                  </Link>
                  {pageData.title}
                </BreadCrumb>
                <SectionPageTitle mb="20px">{pageData.title}</SectionPageTitle>
                <MarkdownContent
                  dangerouslySetInnerHTML={{
                    __html: pageData.content.childMarkdownRemark.html,
                  }}
                />
              </LeftPanel>
              <RightPanel>
                <div className="isSticky">
                  <ContentRightForm />
                </div>
              </RightPanel>
            </MainPanel>
          </Container>
        </Section>
        <EmergencyEstimateCTA
          leftData={pageData.emergencyCta}
          rightData={pageData.estimateCta}
        />
      </Layout>
    )
  }
}

export default ContentTemplate

export const pageQuery = graphql`
  query contentPageQuery($id: String!) {
    contentfulContentPage(id: { eq: $id }) {
      metaTitle
      metaDescription {
        metaDescription
      }
      title
      content {
        childMarkdownRemark {
          html
        }
      }
      emergencyCta {
        title
        linkText
        image {
          gatsbyImageData(quality: 100)
        }
      }
      estimateCta {
        title
        linkText
        image {
          gatsbyImageData(quality: 100)
        }
      }
    }
  }
`
